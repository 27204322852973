<template>
    <div>
        <b-container class="pt-5 pb-5">
            <b-card no-body>
                <b-card-body>
                    <h1 class="fs-4 font-weight-bold mb-3">Komende evenementen</h1>
                    <template v-if="tickets.length > 0">
                        <b-card v-for="(ticket, key) in tickets" :key="key" class="mb-3">
                            <h4 class="fs-5 text-primary mb-0">{{ ticket.shop.company_name }}</h4>
                            <b-row>
                                <b-col sm="6" md="4" class="mb-2">
                                    <div>{{ ticket.event.name }}</div>
                                    <div class="text-muted">
                                        {{ ticket.date.start_at|formatTextDateTime }} - {{ ticket.date.end_at|formatTextDateTime }}
                                    </div>
                                </b-col>
                                <b-col sm="6" md="4" class="mb-2">
                                    <div>{{ ticket.firstname }} {{ ticket.lastname }}</div>
                                    <div class="text-muted">{{ ticket.email }}</div>
                                    <div class="text-muted">Prijs: {{ ticket.total_price|toCurrency }}</div>
                                </b-col>
                                <b-col sm="12" md="4" class="text-right mb-2">
                                    <tempate v-if="!ticket.canceled_at">
                                        <b-button class="mb-2 md-ml-2" variant="primary" size="sm" @click="downloadTicket(ticket)" block>
                                            Ticket downloaden
                                        </b-button>
                                        <template v-if="ticket.event.is_customer_allow_cancellation">
                                            <b-button class="mb-2 md-ml-2" variant="outline-primary" size="sm" @click="initCancelTicket(ticket)" block>
                                                Ticket annuleren
                                            </b-button>
                                        </template>
                                    </tempate>
                                    <template v-else>
                                        <b-button class="mb-2 md-ml-2" variant="dark" size="sm" block disabled>
                                            Geannuleerd
                                        </b-button>
                                    </template>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>
                    <template v-else>
                        <b-alert variant="secondary" show>
                            Er zijn geen tickets gevonden.
                        </b-alert>
                    </template>
                </b-card-body>
            </b-card>
        </b-container>
        <b-modal id="cancel-modal" title="Ticket annuleren">
            <p>Weet je zeker dat je de ticket(s) voor dit evenement <strong>definitief</strong> wilt annuleren?</p>
            <template #modal-footer="{ close }">
                <b-button variant="secondary" @click="close()">Terug</b-button>
                <b-button variant="danger" @click="cancelTicket(cancel)">Definitief annuleren</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default ({
        components: {},
        metaInfo: {
            title: 'Mijn Tickets',
        },
        data() {
            return {
                cancel: null,
                tickets: [],
            }
        },
        methods: {
            getTickets: function() {
                this.tickets = [];
                this.$axios.get("https://api.tixgo.nl/customer/tickets")
                    .then( response => {
                        this.tickets = response.data;
                    }
                );
            },
            downloadTicket(ticket) {
                this.$axios.get("https://api.tixgo.nl/customer/ticket/" + ticket.id)
                    .then( response => {
                        if (response.data.status == 'success') {
                            window.open(response.data.download_url, '_blank');
                        }
                    }
                );
            },
            initCancelTicket(ticket) {
                this.cancel = ticket;
                this.$bvModal.show('cancel-modal');
            },
            cancelTicket(ticket) {
                this.$axios.get("https://api.tixgo.nl/customer/ticket_cancel/" + ticket.id)
                    .then( response => {
                        if (response.data.status == 'success') {
                            this.$bvModal.hide('cancel-modal');
                            this.getTickets();

                            this.$toast.open({
                                message: 'Je annulering is succesvol ontvangen en verwerkt.',
                                type: 'success',
                            });
                        } else {
                            this.$toast.open({
                                message: 'Er is iets fout gegaan. Probeer het opnieuw of neem contact op met de klantenservice voor verdere hulp.',
                                type: 'error',
                            });
                        }
                    }
                );
            },
        },
        created() {
            this.getTickets();
        }
    });
</script>